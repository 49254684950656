/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: Poppins, sans-serif;
    font-size: 62.5%;
    background: #000000;
}
a {
    color: inherit;
    text-decoration: none;
}
button {
    padding: 0;
    border: none;
}
button:focus {
    outline: none;
}
* {
    box-sizing: border-box;
}
.swiper-pagination-bullets {
    bottom: 0px !important;
}
